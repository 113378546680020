<template>
  <div v-for="(tips,clue) in clueList" :key="clue">
    {{ clue }}
    <button @click="getOneClue(clue)" v-if="clueList[clue].length>cluesLevel[clue]">Indice</button>
    <ul>
      <template v-for="tip,index in tips">
        <li v-if="index < cluesLevel[clue]" :key="index">
          {{ tip }}"
        </li>
      </template>
    </ul>
  </div>
</template>

<script>

const clueList = {
  tableau: [
    "Vous avez un tableau qui ne veut absolument rien dire ? C'est ce que vous croyez ! Vous pourriez chercher des indices pour accéder aux bons éléments.",
    "Les indices dans un tableau, ce sont des chiffres, qui peuvent être inscrits en décimal, en binaire, mais qui sont plus utils en héxadécimal.",
    "ça ne veut toujours rien dire ? Vous ne savez pas que dans bon nombres de langages, les indices commencent à 0 ?",
    "Toujours pas ? Vous méritez de perdre ! Une petite barre = 0, une grande barre = 1. Et pour la conversion, essayez de trouver !",
    "Ah, certains éléments sont masqués. Vous ne voyez décidément rien, aucun détail, c'est déprimant... Le tableau se répète. mod 0xB4",
  ],
  barcode: [
    "Si vous êtes arrivés sur ce site, vous devriez pouvoir faire quelque chose avec cette énigme.",
    "Barcode Scanner sous Android. Et sous iOS, et bien débrouillez-vous !"
  ],
  perspective: [
    "Prenez du recul, bon sang... Ne restez pas bornés. Changez de point de vue !"
  ],
  etoile: [
    "DULCTKBSJA, ça ne veut rien dire du tout. Et à l'envers ? Ça a plus de sens ?",
    "A..B..C..D.. à vous maintenant !",
    ". .. :. :: ...: :::"
  ]
}

export default {
  name: 'Clue',
  data() {
    return  {
      cluesLevel: {
        tableau: 0,
        barcode: 0,
        perspective: 0,
        etoile: 0
      }, 
      clueList
    }
  },
  mounted() {
    let savedCluesLevel = JSON.parse(localStorage.getItem('savedCluesLevel'))
    if(savedCluesLevel === null) {
      localStorage.setItem('savedCluesLevel',JSON.stringify(this.cluesLevel))
    } else {
      this.cluesLevel = savedCluesLevel
    }
  },
  methods: {
    getOneClue(topic){
      console.log("getOneClue " + topic)
      if(this.cluesLevel[topic]<clueList[topic].length) {
        this.cluesLevel[topic]++
        console.log(this.clueLevel)
        localStorage.setItem('savedCluesLevel',JSON.stringify(this.cluesLevel))
      }
    }
  }
}
</script>
