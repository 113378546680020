<template>
    <WorldMap remainingTime="0" />
    <div class="alerte">
    <p>Grâce à votre inefficacité, le botnet Glitch est maintenance activé. Dans quelques heures, l'Internet Mondial va s'écrouler, et avec lui les économies.
    Merci de votre participation. N'hésitez pas à rententer votre chance.</p>
    <p>Dans quelques secondes, vous serez redirigé automatiquement vers le site de</p>
    <a href="https://draceo.fr"><img class="logo" src="~@/assets/logo.png" alt="Draceo SARL"/></a>

    </div>
</template>

<script>
import WorldMap from '@/components/WorldMap.vue'
export default {
    name: "Fail",
    components: {
        WorldMap,
    },
    mounted() {
        localStorage.removeItem("savedCluesLevel")
        localStorage.removeItem("cdorigin")
        window.setTimeout(this.redirectToDraceo, 20000);
    },
    methods: {
        redirectToDraceo() {
            window.location = "https://www.draceo.fr"
        }
    }
}

</script>
<style scoped>
    .alerte {
        position: fixed;
        top: 3rem;
        margin: 3rem;
        background-color: rgba(255,255,255,0.4);
        font-weight: bold;
        font-size: 2rem;
    }
    .logo {
        max-width: 80%;
    }
</style>