<template>
    <WorldMap remainingTime="10000000" />
    <div class="alerte">
        <h1>Bravo !</h1>
    <p>Vous avez arrêté le déploiement du botnet Glitch. Dans quelques minutes, toutes les machines auront été nettoyées des moindres traces du ver.</p>
    <p>Une idée originale par </p>
    <a href="https://draceo.fr"><img class="logo" src="~@/assets/logo.png" alt="Draceo SARL"/></a>
    <small>redirection automatique dans quelques secondes</small>
    </div>
</template>

<script>

import WorldMap from '@/components/WorldMap.vue'
export default {
    name: "Win",
    components: {
        WorldMap,
    },
    mounted() {
        localStorage.removeItem("savedCluesLevel")
        localStorage.removeItem('cdorigin')
        window.setTimeout(this.redirectToDraceo, 20000)
    },
    methods: {
        redirectToDraceo() {
            window.location = "https://www.draceo.fr"
        }
    }  
}
</script>
<style scoped>
    .alerte {
        position: fixed;
        top: 3rem;
        margin: 3rem;
        background-color: rgba(255,255,255,0.4);
        font-weight: bold;
        font-size: 2rem;
    }
    .logo {
        max-width: 80%;
    }
</style>