<template>
  <CommandAndControl />
</template>

<script>
import CommandAndControl from '@/components/CommandAndControl.vue'

export default {
  name: 'Cc',
  components: {
    CommandAndControl
  },
  mounted() {
    if (!document.cookie.split('; ').find(row => row.startsWith('cdorigin'))) {
      document.cookie = `cdorigin=${Date.now()}; max-age=${60*80}; SameSite=none`
    }
  }
}
</script>
