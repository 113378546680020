<template>
  <Win />
</template>

<script>
import Win from '@/components/Win.vue'

export default {
  name: 'WinView',
  components: {
    Win
  },
}
</script>
