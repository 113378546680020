<template>
    <div class="headerbar">
        {{ formattedTimeLeft }}
    </div>
    <div class="centerbar">
        <div class="menu">
            <ul>
                <li>Dashboard</li>
                <li>Documentation</li>
            </ul>
        </div>
        <div class="content">
          <div class="worldmap">
            <WorldMap :remainingTime="timeLeft"/>
            <div ref="console">
              <p>Vous pensiez avoir le temps ? Mauvaise nouvelle : 
                en ouvrant cette page vous venez d'activer le déploiment de la 
                charge active sur les agents dormants. Tic tac tic tac...</p>
            </div>
          </div>
          <div class="commands">
            <input type="text" ref="key_input" placeholder="Saisissez la clé secrète"><br/>
            <input type="submit" @click.prevent="checkKey" value="Valider">
            <ul ref="key_history">
              <li v-for="item, index in keyHistoryList" :key="index">
                {{ item[0] }} : {{ item[1] }}-{{ item[2] }}
              </li>
            </ul>
          </div>
          <Clue />
        </div>
    </div>
    <div class="footerbar">
        Glitch Botnet - Command and Control server - ©WatZeFeuk-2022 - CGU
    </div>
</template>

<script>
import Clue from '@/components/Clue.vue'
import router from '@/router'
import WorldMap from '@/components/WorldMap.vue'

export default {
  name: 'CommandAndControl',
  components: {
    Clue,
    WorldMap
  },
  props: {
    msg: String
  },
  data() {
    return {
      timeLimit: this.getCountdownOrigin() + 3600000,
      timePassed: Date.now(),
      timerInterval: null,
      keyHistoryList: [],
      secretKey: ["SECURE","SYSTEM", "SAFE", "DESIGN"],
      tryCount: 0,
    }
  },
  computed: {
      timeLeft() {
          return this.timeLimit - this.timePassed
      },
      formattedTimeLeft() {
      const timeLeft = this.timeLeft
      
      // The largest round integer less than or equal to the result of time divided being by 60.

      const minutes = Math.floor(timeLeft / 60000)
      
      // Seconds are the remainder of the time divided by 60 (modulus operator)
      let seconds = Math.floor((timeLeft % 60000) / 1000)
      
      // If the value of seconds is less than 10, then display seconds with a leading zero
      if (seconds < 10) {
        seconds = `0${seconds}`
      }
      
      // The output in MM:SS format
      return `${minutes}:${seconds}`
    }
  },
  methods: {
      getCountdownOrigin() {
        let theValue = localStorage.getItem('cdorigin')
        if(theValue === null) {
          let theDate = Date.now()
          localStorage.setItem('cdorigin',theDate)
          return theDate
        }
        return Number(theValue)
      },
      startCountdown() {
          this.timerInterval = setInterval(
              () => {
                  switch(Math.floor(this.timeLeft/1000)) {
                    case 3598:
                      this.addConsoleEntry(
                        "Déploiment lancé sur l'Amérique du Sud")
                      break
                    case 2998:
                      this.addConsoleEntry(
                        "Déploiment lancé sur le Groenland")
                      break
                    case 2698:
                      this.addConsoleEntry(
                        "Déploiment lancé sur l'Amérique du Nord")
                      break
                    case 1498:
                      this.addConsoleEntry(
                        "Déploiment lancé sur l'Océanie")
                      break
                    case 1198:
                      this.addConsoleEntry(
                        "Déploiment lancé sur l'Europe")
                      break
                    
                  }
                  if (this.timePassed >= this.timeLimit) {
                      clearInterval(this.timerInterval);
                      router.push('/fail')
                      return false;
                  }
                  return this.timePassed = Date.now()},
            1000);
      },
      addConsoleEntry(text) {
        let console = this.$refs.console
        if(console === undefined) return(true)

        let entry = document.createTextNode(text)
        console.insertBefore(
          entry,
          console.firstChild
        )
      },
      checkKey() {
        this.tryCount++

        
        let theKey = this.$refs.key_input.value
          .split(" ")
          .filter(item=>item!="")
          .map(item=>item.toUpperCase())
        let rightWord=0, rightPlace=0 
        theKey.forEach(
          (word,index) => {
            let position = this.secretKey.indexOf(word)
            console.log(`position for ${word} is ${position}`)
            if(position === -1) {
              return
            } else if(position == index) {
              rightPlace++
            } else {
              rightWord++
            }
          }
        )
        this.keyHistoryList.push([theKey.join(' '),rightPlace,rightWord])
        if(theKey
          .join(" ") == "SECURE SYSTEM SAFE DESIGN") {
            console.log("C'est OK")
            router.push('/win')
          } else {
            if(this.tryCount == 10) {
              alert("Les chiffres à côté de vos tentatives fonctionnent comme au MasterMind : bons mots bien placés, bons mots mal placés")      
            } else if(this.tryCount == 20) {
              alert("RTFM !")
            }
          }
      }
  },
  mounted() {
    this.startCountdown();
  }
}
</script>

<style scoped>
  .headerbar {
    position: fixed;
    width: 100%;
    top: 0;
    font-size: 3rem;
    background: #333;
    color: #0f3;
    font-weight: bold;
  }

  .centerbar {
    padding: 3rem 1rem;
  }

  .footerbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #333;
    color: #0f3;
  }

  input {
    margin: 0.5rem;
  }

  s1 {
    fill: #f00;
  }
</style>