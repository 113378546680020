<template>
  <Fail />
</template>

<script>
import Fail from '@/components/Fail.vue'

export default {
  name: 'FailView',
  components: {
    Fail
  },
}
</script>
